.formItem {
  p, label {
    &.label {
      margin: 0 0 5px;
    }
  }
  label {
    &.label {
      display: block;
    }
  }
}
.mat-form-field {
  min-width: 110px;
  font-family: $font1;
  .mat-select-underline {
    bottom: 2px;
  }
  .mat-input-element {
    font-size: 14px;
    font-weight: 600;
    position: relative;
    line-height: 14px;
    top: 2px;
  }
  .mat-form-field-label-wrapper {
    top: -6px;
    line-height: 16px;
  }
  .mat-form-field-placeholder {
    padding-bottom: 1px;
    font-size: 14px;
    font-weight: 600;
    color: $placeholder;
  }
  .mat-form-field-placeholder-wrapper {
    top: -0.64375em;
    padding-top: 0.64375em;
  }
  &.classicInput {
    .mat-form-field-flex {
      border: 1px solid $border;
      padding-left: 10px;
      padding-right: 10px;
      transition: border-color linear 0.2s;
    }
    .mat-form-field-infix {
      border-top: none;
      padding: 0.6em 0 0.755em;
    }
    .mat-form-field-placeholder {
      top: 1.56em;
    }
    .mat-form-field-underline {
      display: none;
    }
    &.mat-focused .mat-form-field-flex {
      border-color: $dark;
    }
    &.disabled .mat-form-field-flex {
      background: #fcfcfc;
      .mat-input-element:disabled {
        color: $text;
      }
    }
    &.mat-input-invalid .mat-form-field-flex,
    &.mat-form-field-invalid .mat-form-field-flex {
      border-color: $red;
    }
  }
  &.classicInput.textarea {
    .mat-form-field-flex {
      height: auto;
    }
  }
}
